<template>
    <div class="container">      
      <section class="top">
        <h2>COMO FUNCIONA?</h2>
        <br />
        <div class="row justify-content-center">
            <img class="img-como-funciona" src="../../assets/images/comofunciona.png">
        </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  .top {
    padding: 200px 20px 0;
  }
  .img-como-funciona{
    width: 80%;
  }
  </style>